












































import Vue from "vue";
import { Component } from "vue-property-decorator";
import jwt_decode, { JwtPayload } from "jwt-decode";
import differenceInDays from "date-fns/differenceInDays";

@Component
export default class ProfileSettingsTab extends Vue {
  days = 0;

  async logout(): Promise<void> {
    await this.$store.direct.dispatch.authentication.logout();
    this.$router.push("/");
    location.reload();
  }

  created(): void {
    if (!this.$store.direct.getters.authentication.jwt) return;

    const decodedJWT = jwt_decode(this.$store.direct.getters.authentication.jwt) as JwtPayload;
    if (decodedJWT && decodedJWT.exp) this.days = differenceInDays(new Date(decodedJWT.exp * 1000), new Date());
  }

  async goAccount(): Promise<void> {
    if (!this.$store.direct.state.authentication.user) throw new Error("Cannot go to account if guest");

    // If account is not validated, refresh data in case it has been validated somewhere else.
    if (!this.$store.direct.state.authentication.user.accountValidated)
      await this.$store.direct.dispatch.authentication.whoami();

    // Account still not validated, show popup.
    if (!this.$store.direct.state.authentication.user.accountValidated)
      this.$store.direct.commit.SHOW_TOAST({
        text: this.$t("bottomNavigation.buttons.profile.accountNotValidated").toString(),
      });
    // Account validated, go to profile.
    else this.$router.push("/account");
  }
}
