














import Vue from "vue";
import { Component } from "vue-property-decorator";

import Banner from "@/components/Banner.vue";
import GameListComponent from "@/components/GameList.vue";
import GameListCounter from "@/components/GameListCounter.vue";
import GameListSorter from "@/components/GameListSorter.vue";

import { Game } from "@/store/modules/games";

@Component({
  components: {
    Banner,
    GameListComponent,
    GameListCounter,
    GameListSorter,
  },
})
export default class ProfileLibraryTab extends Vue {
  get favoriteGames(): Game[] {
    return this.gamesSorted
      .filter((g) => this.$store.direct.state.games.favoriteGames.includes(g._id))
      .filter((g) => g);
  }

  selectValue = "title";

  get gamesSorted(): Game[] {
    switch (this.selectValue) {
      case "new":
        return this.$store.direct.getters.games.gamesSortedDate;
      case "popular":
        return this.$store.direct.getters.games.gamesSortedPopular;
      case "title":
      default:
        return this.$store.direct.getters.games.gamesSortedAlpha;
    }
  }
}
