

















































import Vue from "vue";
import { Component } from "vue-property-decorator";

import NewHeader from "@/components/NewHeader.vue";
import ProfileLibraryTab from "@/components/ProfileLibraryTab.vue";
import ProfileSettingsTab from "@/components/ProfileSettingsTab.vue";

@Component({
  components: {
    NewHeader,
    ProfileLibraryTab,
    ProfileSettingsTab,
  },
})
export default class ProfileView extends Vue {
  set tab(tab: string) {
    this.$router.replace({ params: { ...this.$route.params, tab } });
  }
  get tab(): string {
    return (this.$route.params.tab as string) || "library";
  }
}
